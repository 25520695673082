body {
	margin: 0;
	font-family: Arial, sans-serif;
	background-color: #112d51;
	color: #ffffff;
}

.container {
	display: flex;
	flex-wrap: wrap;
}

.column {
	flex: 50%;
	padding: 15px;
	box-sizing: border-box;
	height: 100vh;
	flex-direction: column;
}

/* Responsive layout for screens less than 600px wide */
@media (max-width: 600px) {
	.column {
		flex: 80%;
		height: 75vh;
	}

	.column2 {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
	}
}

.column1 {
	display: flex;
	align-items: center;
}
